<template>
  <div>
    <core-page-title />

    <v-container class="mt-3">
      <v-row>
        <v-col>
          <v-btn
            class="mr-2"
            dark
            to="/pesquisas"
          >
            <v-icon
              left
              v-text="'mdi-chevron-left'"
            />
            Voltar
          </v-btn>

          <v-btn
            class="mr-2"
            outlined
            color="blue"
            @click="showDialogCharts = true"
          >
            <v-icon
              left
              v-text="'mdi-chart-pie'"
            />
            Gráficos
          </v-btn>
        </v-col>
      </v-row>

      <v-sheet
        elevation="2"
        class="mt-4 pa-3"
      >
        
        <div v-if="survey">
          <v-row>
            <v-col>
              <h1 class="display-1">
                <b>{{ `#${survey.id} - ${survey.title}` }}</b>
              </h1>
            </v-col>
          </v-row>

          <v-row class="mb-3">
            <v-col
              md="3"
            >
              <v-sheet
                color="cyan"
                elevation="2"
                class="px-3 pt-2"
                dark
              >
                <h1 class="display-1 font-weight-bold mb-3 text-center">Quantidade de respostas</h1>
                <v-row align="center">
                  <v-col>
                    <v-progress-circular
                      :rotate="-90"
                      :size="50"
                      :width="5"
                      :value="countPercentage"
                      color="white"
                    >
                      {{ `${countPercentage}%` }}
                    </v-progress-circular>
                  </v-col>
                  <v-col>
                    <span class="display-1">
                      {{ `${count}/${countCities}` }}
                    </span>
                  </v-col>
                </v-row>
              </v-sheet>
            </v-col>
          </v-row>
        </div>
      </v-sheet>

      <v-row class="mt-3">
        <v-col>  
          <v-sheet elevation="1">
            <v-data-table
              disable-sort
              dense
              :server-items-length="count"
              :loading="loading"
              :headers="headers"
              :items="answers"
              :options.sync="pagination"
              :items-per-page="20"
              :footer-props="{
                'items-per-page-options': [10, 20, 50, 75]
              }"
              @update:options="loadAnswers"
            >
              <template
                v-slot:[`item.dateLastAnswer`]="{ item }"
                class="text-right"
              >
                {{ item.dateLastAnswer | date('DD/MM/YYYY HH:mm') }}
              </template>

              <template
                v-slot:[`item.actions`]="{ item }"
                class="text-right"
              >
                <v-btn
                  icon
                  small
                  @click="openDialogAnswers(item)"
                >
                  <v-icon
                    v-text="'mdi-eye'"
                    small
                  />
                </v-btn>

                <v-btn
                  icon
                  small
                >
                  <v-icon
                    v-text="'mdi-delete'"
                    small
                    @click="clickDeleteItem(item)"
                  />
                </v-btn>
              </template>
            </v-data-table>
          </v-sheet>
        </v-col>
      </v-row>
    </v-container>

    <dialog-delete
      v-model="showDialogDelete"
      :loading="loadingDelete"
      @confirm="deleteItem"
    />

    <dialog-survey-contact-answers
      v-if="showId"
      v-model="showDialogAnswers"
      :key="showId"
      :survey-id="survey.id"
      :contact-id="showId"
    />

    <dialog-survey-answers-charts
      v-if="showDialogCharts"
      v-model="showDialogCharts"
      :survey-id="parseInt($route.params.id)"
      :key="$route.params.id"
    />

  </div>
</template>

<script>
  import surveysApi from '@/api/surveys'
  import surveysAnswersApi from '@/api/surveys-answers'

  export default {

    components: {
      DialogDelete: () => import('@/components/dialog/DialogDelete'),
      DialogSurveyContactAnswers: () => import('@/components/dialog/surveys/DialogSurveyContactAnswers'),
      DialogSurveyAnswersCharts: () => import('@/components/dialog/surveys/DialogSurveyAnswersCharts'),
    },

    data () {
      return {
        loading: false,
        loadingDelete: false,
        showDialogDelete: false,
        showDialogAnswers: false,
        showDialogCharts: false,
        deleteId: null,
        showId: null,
        survey: null,
        answers: [],
        pagination: {},
        count: 0,
        countCities: 497,
        headers: [
          { text: 'Contato', value: 'contactName' },
          { text: 'Cidade', value: 'cityName' },
          { text: 'Data/Hora', value: 'dateLastAnswer' },
          { text: '', value: 'actions', align: 'right' },
        ],
      }
    },

    computed: {
      countPercentage () {
        return parseInt((this.count / this.countCities) * 100)
      }
    },

    mounted () {
      this.loadSurvey()
    },

    methods: {

      async loadSurvey () {
        try {
          this.loading = true

          const response = await surveysApi.get(this.$route.params.id)

          this.survey = response.data.survey
        } catch (e) {
          this.$snackbar.show({ color: 'error', message: this.$apiError._(e) })
        } finally {
          this.loading = false
        }
      },

      async loadAnswers () {
        try {
          this.loading = true

          const response = await surveysAnswersApi.list(this.$route.params.id, {
            limit: this.pagination.itemsPerPage,
            offset: (this.pagination.page * this.pagination.itemsPerPage) - this.pagination.itemsPerPage,
          })

          this.answers = response.data.answers
          this.count = response.data.count
        } catch (e) {
          this.$snackbar.show({ color: 'error', message: this.$apiError._(e) })
        } finally {
          this.loading = false
        }
      },
  
      openDialogAnswers (answer) {
        this.showId = answer.contactId
        this.showDialogAnswers = true
      },

      async clickDeleteItem (answer) {
        this.deleteId = answer.contactId
        this.showDialogDelete = true
      },

      async deleteItem () {
        try {
          this.loadingDelete = true

          await surveysAnswersApi.deleteAllAnswersByContact(this.survey.id, this.deleteId)

          this.loadAnswers()

          this.$snackbar.show({ color: 'success', message: this.$messages._('delete_success') })
        } catch (e) {
          this.$snackbar.show({ color: 'error', message: this.$apiError._(e) })
        } finally {
          this.loadingDelete = false
          this.showDialogDelete = false
        }
      },

    },

  }
</script>
