import axios from '@/plugins/axios'

export default {

  async list (surveyId, data) {
    const params = new URLSearchParams(data).toString()
    return await axios.get(`surveys/${surveyId}/answers?${params}`)
  },

  async listAnswersByContact (surveyId, contactId) {
    return await axios.get(`surveys/${surveyId}/answers/get-by-contact/${contactId}`)
  },

  async deleteAllAnswersByContact (surveyId, contactId) {
    return await axios.delete(`surveys/${surveyId}/answers/delete-by-contact/${contactId}`)
  },

  async closeAnswersCharts (surveyId) {
    return await axios.get(`surveys/${surveyId}/answers/charts`)
  },

}
