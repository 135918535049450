import axios from '@/plugins/axios'

export default {

  async list (data) {
    const params = new URLSearchParams(data).toString()
    return await axios.get(`surveys?${params}`)
  },

  async get (id) {
    return await axios.get(`surveys/${id}`)
  },

  async insert (data) {
    return await axios.post(`surveys`, data)
  },

  async update (id, data) {
    return await axios.put(`surveys/${id}`, data)
  },

  async delete (id) {
    return await axios.delete(`surveys/${id}`)
  },

  async sendSms (id, data) {
    return await axios.post(`surveys/${id}/send-sms`, data)
  },

  async downloadCsvAnswers (id) {
    return await axios.get(`surveys/${id}/download-csv-answers`)
  },

  async citiesThatDidNotRespond (id) {
    return await axios.get(`surveys/${id}/cities-that-did-not-respond`)
  },

}
